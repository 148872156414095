import { ReactComponent as HatoSvg } from "./noun-icons/noun-happy-352735.svg";
//import { ReactComponent as QnASvg } from "./noun-icons/noun-ask-6978692.svg";
import { ReactComponent as QnASvg } from "./noun-icons/noun-answer-6506171.svg";
import { ReactComponent as JobSvg } from "./noun-icons/noun-job-7182417.svg";
import { ReactComponent as DocSvg } from "./noun-icons/noun-result-6360301.svg";
import { ReactComponent as CircleSvg } from "./noun-icons/noun-circles-1040342.svg";
import { ReactComponent as HashSvg } from "./noun-icons/noun-hashtag-button-1903675.svg";
import { ReactComponent as NotebookSvg } from "./noun-icons/noun-notebook-7041373.svg";
import { ReactComponent as PageSvg } from "./noun-icons/noun-note-2044227.svg";
import { ReactComponent as FlagSvg } from "./noun-icons/noun-flag-7256527.svg";
import { ReactComponent as StoreSvg } from "./noun-icons/noun-app-store-6920679.svg";
import { ReactComponent as CategorySvg } from "./noun-icons/noun-category-6252757.svg";
import { ReactComponent as NotDoneSvg } from "./noun-icons/noun-puzzle-jigsaw-7035248.svg";
import { ReactComponent as DoneSvg } from "./noun-icons/noun-jigsaw-puzzle-5301309.svg";

import './Icons.css';

const HatoIcon = (props) => (
    <HatoSvg className="my-icon" {...props} />
);

const QnAIcon = (props) => (
    <QnASvg className="my-icon" {...props} />
);

const JobIcon = (props) => (
    <JobSvg className="my-icon" {...props} />
);

const DocIcon = (props) => (
    <DocSvg className="my-icon" {...props} />
);

const CircleIcon = (props) => (
    <CircleSvg className="my-icon" {...props} />
);

const HashIcon = (props) => (
    <HashSvg className="my-icon" {...props} />
);

const NotebookIcon = (props) => (
    <NotebookSvg className="my-icon" {...props} />
);

const PageIcon = (props) => (
    <PageSvg className="my-icon" {...props} />
);

const FlagIcon = (props) => (
    <FlagSvg className="my-icon" {...props} />
);

const StoreIcon = (props) => (
    <StoreSvg className="my-icon" {...props} />
);

const CategoryIcon = (props) => (
    <CategorySvg className="my-icon" {...props} />
);

const NotDoneIcon = (props) => (
    <NotDoneSvg className="my-icon" {...props} />
);

const DoneIcon = (props) => (
    <DoneSvg className="my-icon" {...props} />
);

export {HatoIcon, QnAIcon, JobIcon, DocIcon, CircleIcon, HashIcon,
	NotebookIcon, PageIcon, FlagIcon, StoreIcon, CategoryIcon,
	NotDoneIcon, DoneIcon,
	};

