import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate, 
		useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading,  Code,
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout, Spinner, DataList, Switch, AlertDialog,
	Checkbox, RadioGroup,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import ReactMarkdown from 'react-markdown';
// import JSONPretty from 'react-json-pretty';
import stringify from 'json-stringify-pretty-compact';

import { HatoIcon, QnAIcon, CircleIcon, JobIcon, DocIcon,
	NotebookIcon, PageIcon, FlagIcon, CategoryIcon,
	} from './Icons';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon, 
	ChevronRightIcon, ChevronDownIcon,
	TriangleRightIcon, TriangleDownIcon,
	TrackNextIcon, TrackPreviousIcon,
	ExclamationTriangleIcon, TrashIcon, GroupIcon,
	RulerSquareIcon,
	} from '@radix-ui/react-icons'

import './Store.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	MiniDateStr, DateStr, TabHeading, first_name, anon_show, randn,
	ShowJson, cap, AiFetchPoll, SpeechToText, 
	HighlightedText, json_mean, SearchBar, my_author_id, goto_nb,
	HasBusyPages, get_my_hids, isEmpty,
	} from './Lib';


//-------------------------

// leading space to have it show up at top in categories
const MY_HATO_LAB = ' My Hato Lab';	

function MakeHatoMenu({rmHato}){

	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  <DotsVerticalIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<Cross1Icon />} onClick={rmHato} >
      	Delete Hato
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}


function HatoInfo({hato}) {
	return (
	<Box>
  <DataList.Root size="2" >

    <DataList.Item>
      <DataList.Label width="2rem" >
        Name
      </DataList.Label >
      <DataList.Value >
      	{hato.name}
      </DataList.Value >
    </DataList.Item>


    <DataList.Item>
      <DataList.Label width="2rem" >
        Handle
      </DataList.Label >
      <DataList.Value >
      	@{hato.handle}
      </DataList.Value >
    </DataList.Item>


    <DataList.Item>
      <DataList.Label width="2rem" >
        Category
      </DataList.Label >
      <DataList.Value >
      	{hato.graph.Settings.category}
      </DataList.Value >
    </DataList.Item>


    <DataList.Item>
      <DataList.Label width="2rem" >
        Description
      </DataList.Label >
      <DataList.Value >
      	{hato.graph.Settings.description}
      </DataList.Value >
    </DataList.Item>


    <DataList.Item>
      <DataList.Label width="2rem" >
        By
      </DataList.Label >
      <DataList.Value >
      	{hato.maker.name}
      </DataList.Value >
    </DataList.Item>

  </DataList.Root >

	</Box>
	);
}
/*
	<Code className="jhatricka">
		<JSONPretty data={hato.graph} />
	</Code>
*/

function HatoJson({hato}) {
	const gbuf = stringify(hato.graph)
			.replace(/\\n/g, '\n')
			.replace(/\\t/g, '    ')
			;
	const [jhato, setJhato] = useState(gbuf);

	const update = async (e) => {
		e.preventDefault();
		const mkid = hato.maker_id;
		// const esc_jhato = jhato.replace(/\n/g, "\\n"); 
		
		const data = await server_post('/api/hato/update/',
				{ mkid, jhato });

		if (data && !data.error) {
			hato.graph = data.graph;
			notify({message: 'Hato Updated'});
		}
	}

	
	return (
	<Box >
		<TextArea size="2" variant="soft" color="gold"
			className="jhatricka"
			value={jhato}
	  onChange={e=>setJhato(e.target.value)}
		/>
        <Button variant="soft" type="submit" onClick={update} 
		mt="2" 
	>
	  Update
	</Button>
	</Box>
	);
}

function HatoHatricka({hato}) {
	return (
	<Box >
		<Text color="red" weight="bold" >
			Under construction.
		</Text>
		<TextArea size="2" variant="soft" color="gold"
			className="jhatricka"
			defaultValue={hato.hato.script} 
		/>
	</Box>
	);
}


function MyTabs({tab, setTab}) {

	return (
    <TabNav.Root>

      <TabNav.Link href="#" active={tab=='info'} 
      	onClick={e=>setTab('info')}
      >
	  <Text>
	    About
	  </Text>
      </TabNav.Link>

      <TabNav.Link href="#" active={tab=='jhatricka'}
      	onClick={e=>setTab('jhatricka')}
      >
	  <Text>
	    Json
	  </Text>
      </TabNav.Link>


      <TabNav.Link href="#" active={tab=='hatricka'}
      	onClick={e=>setTab('hatricka')}
      >
	  <Text>
	    Hatricka
	  </Text>
      </TabNav.Link>


    </TabNav.Root>
    	);
}


function ShowHato({hid, isSub, refresh}) {
	const root = window.my;
	const hato = root.store[hid];
	const [tab, setTab] = useState('info');
	const [scrollRef, setScrollRef] = useState(null);
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const [isChecked, setIsChecked] = useState(isSub);

	const navigate = useNavigate();

	hato.graph = JSON.parse(hato.hato.jgraph);

	const rmHato2 = () => {
		setIsAlertDialogOpen(true);
	}

	const rmHato = async (e) => {
		const data = await server_post('/api/hato/delete/', {hid});

		if (data && !data.error) {
			delete root.store[hid];
			refresh();
		}
	}

	const addSub = async (e) => {
		const data = await server_post('/api/hato/add/', {hid});

		if (data && !data.error) {
			root.circles = {...root.circles, ...data.circles};
			setIsChecked(true);
			refresh();
		}
	}

	const removeSub = async (e) => {
		const data = await server_post('/api/hato/remove/', {hid});

		if (data && data.cids && !data.error) {
			data.cids.forEach(cid => {
				delete root.circles[cid];
			});
			setIsChecked(false);
			refresh();
		}
	}

	const toggleSub = async (e) => {
		if (isSub)
			removeSub();
		else
			addSub();
	}

	return (
	<>
<Box p="2" my="4">
{/*
    <Flex align="center" justify="between" m="2" >

      <TabHeading scrollRef={scrollRef}>
        Hato
      </TabHeading>

      <Text>
      </Text>

    </Flex>
*/}
    <MyTabs tab={tab} setTab={setTab} />
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Box p="2" mt="2">

    {tab == 'info' && <HatoInfo hato={hato} />}
    {tab == 'jhatricka' && <HatoJson hato={hato} />}
    {tab == 'hatricka' && <HatoHatricka hato={hato} />}

  </Box>
</Content>
</Scrollable>


    {/* Footer */}
<AlertDialog.Root open={isAlertDialogOpen} onOpenChange={setIsAlertDialogOpen} >
  <AlertDialog.Content maxWidth={window.colw} >
    <AlertDialog.Title>Delete Hato</AlertDialog.Title>
    <AlertDialog.Description size="2">
      Are you sure? This will delete hato and all subscriptions
      and notebooks.
      <br/>
      This is not recoverable.
    </AlertDialog.Description>

    <Flex gap="3" mt="4" justify="end">
      <AlertDialog.Cancel>
        <Button variant="soft" color="gray">
          Cancel
        </Button>
      </AlertDialog.Cancel>
      <AlertDialog.Action>
        <Button variant="solid" color="red" onClick={rmHato}>
          Delete Hato
        </Button>
      </AlertDialog.Action>
    </Flex>
  </AlertDialog.Content>
</AlertDialog.Root>
    
    <Flex justify="between" mt="5" p="2"
    	className="dream-footer"
    >
      <Flex align="center" gap="2" >
	<Checkbox checked={isChecked} onCheckedChange={toggleSub} /> 
	<Text size="1">
		Subscribe
	</Text>
      </Flex>
      <Flex align="center" gap="2" >
      <MakeHatoMenu rmHato={rmHato2} refresh={refresh} />
      </Flex>
    </Flex>
    
</Box>
	</>
	);
}


function goto_hato(navigate, hid, curHid) {
	if (hid == curHid)
		hid = -1;
	navigate(`/store/hato/${hid}`);
}

function HatoHead({hid, curHid, myHids, refresh}) {
	const root = window.my;
	const hato = hid ? root.store[hid] : {name: 'None yet'};
	const navigate = useNavigate();
	const got = myHids.indexOf(hid) >= 0;
	const sel = (hid == curHid);

	return (
	<>
<Box p="2" my="4" className={"hato-head " + (sel? 'hato-sel' : '')}
	onClick={e => goto_hato(navigate, hid, curHid)}
>
<Flex justify="between" align="center" >
	<HatoIcon />
	<Flex flexGrow="1" px="2" gap="2" align="center">
		<Text>
			{hato.name}
		</Text>
	</Flex>
	<Flex gap="2">
	{ got ? <Checkbox defaultChecked disabled /> : null}
	{ hid == curHid ?  <ChevronDownIcon /> : <ChevronRightIcon /> }
	</Flex>
</Flex>
</Box>
{sel && (hid > 0) && 
	<ShowHato hid={hid} refresh={refresh} isSub={got} />
}
	</>
	);
}

function PopupCreateHato({refresh}) {
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const my = window.my;
	const [choice, setChoice] = useState('');

	const createHato = async (e) => {
		e.preventDefault();

		let data = null, error = '', cid=0;
		const text='';

		data = await server_post('/api/hato/create/', {choice});

		if (data) {
			my.store = {...my.store, ...data.store};
			notify({message: 'Created'});
			setIsAlertDialogOpen(false);
			refresh();
		}
	}

	return (
	<Box>
<AlertDialog.Root open={isAlertDialogOpen} onOpenChange={setIsAlertDialogOpen} >
  <AlertDialog.Content maxWidth={window.colw} >
    <AlertDialog.Title>Create Hato</AlertDialog.Title>
    <AlertDialog.Description size="2">
    	Start with an example and modify it.
	<Text size="1" as="div" color="yellow">
	Hatricka compiler is being built. Use JSON Hatricka For now. 
	</Text>

<Box ml="4" mb="2" >
  <RadioGroup.Root name="example" onValueChange={setChoice} value={choice} >
  {Object.keys(my.examples).map( (handle, i) => <RadioGroup.Item 
  				key={i} value={handle}>
  		<Text weight="bold"> {handle} </Text>: {my.examples[handle].about}
	</RadioGroup.Item>)}
  </RadioGroup.Root>
</Box>

    </AlertDialog.Description>

    <Flex gap="3" mt="4" justify="end">
      <AlertDialog.Cancel>
        <Button variant="soft" color="gray">
          Cancel
        </Button>
      </AlertDialog.Cancel>
      <AlertDialog.Action>
        <Button variant="solid" color="green" onClick={createHato}>
          Create
        </Button>
      </AlertDialog.Action>
    </Flex>
  </AlertDialog.Content>
</AlertDialog.Root>
		      <Tooltip content="Create Hato">
        <IconButton variant="soft" ml="6" mr="2" 
		onClick={e=>setIsAlertDialogOpen(true)} >
	  <PlusIcon />
	</IconButton>
		      </Tooltip>
	</Box>
	)
}


function CatHead({cat, curHid, myHids, search, refresh}) {
	const store = window.my.store;
	const is_lab = cat.cat == MY_HATO_LAB;
	const my = window.my;
	let hids = [];

	const regexp = new RegExp(search.text, 'i');
	if (regexp.test(cat.cat))
		hids = [...cat.hato];
	else {
		hids = cat.hato.filter(hid => regexp.test(store[hid].name));
	}

	if (hids.length == 0)
		return null;


	return (
	<>
<Box p="2" my="4" className="category-head"
>
<Flex justify="between" align="center" >
	{is_lab ? <RulerSquareIcon /> : <CategoryIcon />}
	<Flex flexGrow="1" px="2" gap="2" align="center">
		<Text weight="medium">
			{cat.cat}
		</Text>
	</Flex>
	<Flex gap="2">
		{is_lab && <PopupCreateHato refresh={refresh} />}
	</Flex>
</Flex>
</Box>
<Box ml="3">
	{hids.map(hid => <HatoHead
		hid={hid}
		key={hid} 
		curHid={curHid}
		myHids={myHids}
		refresh={refresh}
		/>)}
</Box>
	</>
	);
}

//---

function catsort(store) {
	const cats = {}; // {'learning' : {5 : 'spanish', 7: 'latin'}, ...}

	cats[MY_HATO_LAB] =  {};

	Object.values(store).forEach( item =>  {
		let cat = item.category;

		if (item.state == 'd')
			cat = MY_HATO_LAB;

		if (cats[cat] === undefined)
			cats[cat] = {};

		cats[ cat ][ item.id ] = item.name;
	});

	if (isEmpty(cats[MY_HATO_LAB]))
		cats[MY_HATO_LAB] =  {0 : 'None yet'};  
		
	const scats = []; // [ {'name' : learning' : 'hato' : [7,5]}, ... ]

	Object.keys(cats).sort().forEach(cat => {
		const cath = cats[cat];

		const item = {cat};
		item.hato = Object.keys(cath).sort(
			(a,b) => cath[a].localeCompare(cath[b])
		).map(Number);

		scats.push( item );
	});

	// console.log('store.catsort', scats);

	return scats;
}


// This shows notebooks across all circles
function Store() {
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);
	const navigate = useNavigate();
	const {curHid} = useParams();
	const my = window.my;
	const store = my.store;

	const cats = catsort(store);

	const refresh = MkRefresh();
	
	const narrow = hid => {
		const hato = store[hid];

		if (search.fav && !hato.is_favorite)
			return false;

		if (search.text) {
			const regexp = new RegExp(search.text, 'i');
			if (!regexp.test(hato.name))
				return false;
		}

		return true;
	}

	const add = async (e) => {
		e.preventDefault();

		let data = null, error = '', cid=0;
		const text='';

		data = await server_post('/api/notebook/add/', {cid});

		if (data) {
			const nid = Object.keys(data)[0];
			const my = window.my;
			// my.circles[cid].notebooks[nid] = data[nid];
			// my.current.nid = nid;
			// setCurnb(nid);
		}
	}


	let hids = Object.keys(store)
			.filter(hid => narrow(hid))
			.map(Number)
			.sort((a,b) => b - a);
	
	let myHids = get_my_hids();

	return (
	<>
<Content >
    <Flex align="center" justify="between" m="2" className="no-break" 
    		gap="2" >

      <TabHeading scrollRef={scrollRef} >
       Hato Store
      </TabHeading>

      <SearchBar search={search} setSearch={setSearch} />

    </Flex>
</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Card>
    {cats.map((cat, i) => <CatHead key={i} 
    		cat={cat} 
		search={search}
		curHid={curHid}
		myHids={myHids}
		refresh={refresh}
		/>
    )}
  </Card>
</Content>
</Scrollable>
	</>
	);
}



export {Store};
