import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout, AlertDialog, DataList, Switch,
	Spinner, Select,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, Pencil1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, UploadIcon,
	} from '@radix-ui/react-icons'

import './Profile.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	DateStr, TabHeading, first_name, anon_show,
	} from './Lib';


//-------------

//----------

function ProfileMenu({rmAccount}){
	const navigate = useNavigate();

	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  <DotsVerticalIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<Pencil1Icon />} 
			onClick={e=>navigate('/profile/update')} >
      	Update Profile
      </DropdownMenu.Item>
      <DropdownMenu.Item shortcut={<Cross1Icon />} onClick={rmAccount} >
      	Delete Account
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}


function UpdateProfile({refresh}) {
	const [scrollRef, setScrollRef] = useState(null);
	const [working, setWorking] = useState(false);
	const [name, setName] = useState(window.my.name);
	const firstRef = useRef(null);

	const [enableSpeech, setEnableSpeech] = 
			useState(window.my.settings.enableSpeech);

	const navigate = useNavigate();
	const my = window.my;

	const close = async(e) => {
		e.preventDefault();
		window.my.handlers.goBack(); 
	}

	const update = async (e) => {
		e.preventDefault();

		setWorking(true);
		const data = await server_post('/api/profile/update/',
				{ 
				name,
				/*
				settings: {
					enableSpeech,
				}
				*/
				});

		if (data && !data.error) {
			window.my.name = data.name;
			refresh();
			notify({message: 'Profile Updated'});
		}
		setWorking(false);
	}

	useEffect( () => {
		window.my.handlers.showBackMenu(); 
		if (firstRef.current)
			firstRef.current.focus();
	}, []);
	
	return (
	<>
<Content>
    <Flex align="center" justify="between" m="2" >

    <TabHeading scrollRef={scrollRef}>
      Update Profile
    </TabHeading>

    </Flex>
</Content>

<Scrollable setScrollRef={setScrollRef} >
<Content>
  <Card className="profile">

  <DataList.Root size="2" >

    <DataList.Item>
      <DataList.Label width="4rem" >
      	Name
      </DataList.Label >
      <DataList.Value >
	<TextField.Root
	  id="name"
	  type="text"
	  variant="classic"
	  placeholder="Your Name"
	  value={name}
	  ref={firstRef}
	  onChange={e=>setName(e.target.value)}
	 />
      </DataList.Value >
    </DataList.Item>

    <DataList.Item>
      <DataList.Label >
      </DataList.Label >
      <DataList.Value >
      <Flex gap="5">
        <Button variant="soft" type="submit" onClick={update} >
	  {working ? 
	  	<Spinner /> :
		  <Pencil1Icon />}
	  Update
	</Button>
      </Flex>
      </DataList.Value >
    </DataList.Item>

  </DataList.Root >

    {/* Footer */}
  <br/>
    
    <Flex justify="between" mt="5" p="2"
    	className="profile-footer"
    >
      <Text>
      </Text>
      <Flex align="center" gap="2" >
           <Tooltip content="Update Profile">
        <Button variant="soft" onClick={update} >
	  <Pencil1Icon /> 
	</Button>
	   </Tooltip>

           <Tooltip content="Close">
        <Button variant="soft" onClick={close} >
	  <Cross1Icon /> 
	</Button>
	   </Tooltip>
      </Flex>
    </Flex>
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}

function Profile({refresh}) {
	const [search, setSearch] = useState({text: '', fav: false});
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const [scrollRef, setScrollRef] = useState(null);
	const [working, setWorking] = useState(false);

	const [enableSpeech, setEnableSpeech] = 
			useState(window.my.settings.enableSpeech);

	const navigate = useNavigate();
	const my = window.my;

	const rmAccount2 = () => {
		setIsAlertDialogOpen(true);
	}

	const rmAccount = async (e) => {
		const data = await server_post('/api/delete/');

		if (data && !data.error) {
			window.my = {...window.my_default};
			navigate('/');
			refresh();
		}
	}

	const close = async(e) => {
		e.preventDefault();
		window.my.handlers.goBack(); 
	}

	useEffect( () => {
		window.my.handlers.showBackMenu(); 
	}, []);
	
	return (
	<>
<Content>
    <Flex align="center" justify="between" m="2" >

    <TabHeading scrollRef={scrollRef}>
      Profile
    </TabHeading>

    </Flex>
</Content>

<Scrollable setScrollRef={setScrollRef} >
<Content>
  <Card className="profile">

  <DataList.Root size="2" >

    <DataList.Item>
      <DataList.Label width="4rem" >
      	Name
      </DataList.Label >
      <DataList.Value >
      	{my.name}
      </DataList.Value >
    </DataList.Item>

    <DataList.Item>
      <DataList.Label >
      	Email
      </DataList.Label >
      <DataList.Value >
      	{my.email}
      </DataList.Value >
    </DataList.Item>

    <DataList.Item>
           <Tooltip content="For Anonymous Blogs">
      <DataList.Label >
      	Anonymous ID
      </DataList.Label >
	   </Tooltip>
      <DataList.Value >
      	{my.anon_code}
      </DataList.Value >
    </DataList.Item>


    <DataList.Item>
      <DataList.Label >
      </DataList.Label >
      <DataList.Value >
      <Flex gap="5">
      {/*
        <Button variant="soft" type="submit" onClick={update} >
	  {working ? 
	  	<Spinner /> :
		  <UploadIcon />}
	  Update
	</Button>
      */}
      </Flex>
      </DataList.Value >
    </DataList.Item>

  </DataList.Root >

  {working && false && 
<Callout.Root my="2" >
  <Callout.Icon>
    <InfoCircledIcon />
  </Callout.Icon>
  <Callout.Text>
    Place lookup can take a few seconds
  </Callout.Text>
</Callout.Root>
   }

    {/* Footer */}
  <br/>

<AlertDialog.Root open={isAlertDialogOpen} onOpenChange={setIsAlertDialogOpen} >
  <AlertDialog.Content maxWidth={window.colw} >
    <AlertDialog.Title>Delete Account</AlertDialog.Title>
    <AlertDialog.Description size="2">
      Are you sure? This will delete your account and all related data.
      This is not recoverable.
    </AlertDialog.Description>

    <Flex gap="3" mt="4" justify="end">
      <AlertDialog.Cancel>
        <Button variant="soft" color="gray">
          Cancel
        </Button>
      </AlertDialog.Cancel>
      <AlertDialog.Action>
        <Button variant="solid" color="red" onClick={rmAccount}>
          Delete Account
        </Button>
      </AlertDialog.Action>
    </Flex>
  </AlertDialog.Content>
</AlertDialog.Root>

    
    <Flex justify="between" mt="5" p="2"
    	className="profile-footer"
    >
      <Text>
      </Text>
      <Flex align="center" gap="2" >
           <Tooltip content="Update Profile">
        <Button variant="soft" onClick={e=>navigate('/profile/update')} >
	  <Pencil1Icon /> 
	</Button>
	   </Tooltip>

           <Tooltip content="Close">
        <Button variant="soft" onClick={close} >
	  <Cross1Icon /> 
	</Button>
	   </Tooltip>
      <ProfileMenu rmAccount={rmAccount2} />
      </Flex>
    </Flex>
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}


export {Profile, UpdateProfile};
