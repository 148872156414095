import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate, 
		useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout, Spinner, AlertDialog, Popover,
	Checkbox, Avatar, Separator,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { HatoIcon, QnAIcon, CircleIcon, JobIcon, DocIcon,
	NotebookIcon, PageIcon, FlagIcon,
	} from './Icons';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon, 
	ChevronRightIcon, ChevronDownIcon,
	TriangleRightIcon, TriangleDownIcon,
	TrackNextIcon, TrackPreviousIcon,
	ExclamationTriangleIcon, ReloadIcon,
	EnterFullScreenIcon, ExitFullScreenIcon,
	ChatBubbleIcon, CheckIcon, CheckCircledIcon,
	Cross2Icon, CopyIcon, EnvelopeClosedIcon,
	LockClosedIcon, LockOpen1Icon, LockOpen2Icon,
	} from '@radix-ui/react-icons'

import './Notebooks.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	MiniDateStr, DateStr, TabHeading, first_name, anon_show, randn,
	ShowJson, cap, AiFetchPoll, SpeechToText, 
	HighlightedText, json_mean, SearchBar, 
	HasBusyPages,
	} from './Lib';
import {ShowPage, ShowReport} from './Page';

//-----------

function NotebookMenu({rmNotebook, emailMe}){
	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  <DotsVerticalIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<Cross1Icon />} onClick={rmNotebook} >
      	Delete
      </DropdownMenu.Item>
      <DropdownMenu.Item shortcut={<EnvelopeClosedIcon />} onClick={emailMe} >
      	Email Me
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}

function AccessMenu({notebook, refresh}){
	const accessIcon = {
		'r' : 
		      <Tooltip content="Private">
			<LockClosedIcon />
		      </Tooltip>,
		'a' : 
		      <Tooltip content="Anonymous">
			<LockOpen1Icon />
		      </Tooltip>,
		'p' : 
		      <Tooltip content="Public">
			<LockOpen2Icon />
		      </Tooltip>
	}[notebook.access];

	const set_access = async (access) => {
		const aid =  notebook.my_author_id;
		const data = await server_post(
			`/api/notebook/mod/`,
				{aid, op: 'access', access},
				);

		if (data) {
			notebook.access = access;
			notify({message: 'Access updated'});
			refresh();
		}
	}

	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  {accessIcon}
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<LockClosedIcon />} 
      		onClick={e=>{set_access('r')}} >
      	Private
      </DropdownMenu.Item>
      <DropdownMenu.Item shortcut={<LockOpen1Icon />} 
      		onClick={e=>{set_access('a')}} >
      	Anonymous
      </DropdownMenu.Item>
      <DropdownMenu.Item shortcut={<LockOpen2Icon />} 
      		onClick={e=>{set_access('p')}} >
      	Public
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}


function page_ids(notebook) {
	return Object.keys(notebook.pages)
			.map(Number)
			.sort((a,b) => a - b);
}

function last_page_id(notebook) {
	const pgids = page_ids(notebook);
	if (pgids.length)
		return pgids[ pgids.length - 1 ];
	return -1;
}

function next_page_id(notebook, pgid) {
	const pgids = page_ids(notebook);
	const i = pgids.indexOf(pgid);
	if ((i+1) < pgids.length)
		return pgids[i+1];
	return pgid;
}

function previous_page_id(notebook, pgid) {
	const pgids = page_ids(notebook);
	const i = pgids.indexOf(pgid);
	if (i > 0)
		return pgids[i-1];
	return pgid;
}

function PageNav({page, notebook, previousPage, nextPage}) {
	const navRef = useRef(null);

	useEffect( () => {
		const handleKeyDown = (ev) => {
			const isInputFocused = 
				document.activeElement.tagName === "INPUT" || 
				document.activeElement.tagName === "TEXTAREA";

			if (!isInputFocused) {
				switch(ev.key) {
					case 'ArrowLeft':
						previousPage();
						break;

					case "ArrowRight":
						nextPage();
						break;
				}
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		if (navRef.current) {
			navRef.current.scrollIntoView({ 
				behavior: 'smooth', block: 'end',
			});
		}

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [page, notebook, previousPage, nextPage]);


	return (

      <Flex gap="3" align="center" ref={navRef} >
        <IconButton variant="ghost" onClick={previousPage} >
	  <TrackPreviousIcon />
	</IconButton>
        <Text>
	  Pg {page.number + 1} / {page_ids(notebook).length}
	</Text>
        <IconButton variant="ghost" onClick={nextPage} >
	  <TrackNextIcon />
	</IconButton>
      </Flex>

	);
}

function MyTabs({tab, setTab}) {

	return (
    <TabNav.Root>

      <TabNav.Link href="#" active={tab=='report'} 
      	onClick={e=>setTab('report')}
      >
	  <Text>
	    Report
	  </Text>
      </TabNav.Link>

      <TabNav.Link href="#" active={tab=='pages'}
      	onClick={e=>setTab('pages')}
      >
	  <Text>
	    Pages
	  </Text>
      </TabNav.Link>

    </TabNav.Root>
    	);
}

function ShowNotebook({cid, nid, refresh, openFirstNb}) {
	const root = window.my;
	const [isFullScreen, setIsFullScreen2] = useState(
					root.settings.isFullScreen);
	const [copied, setCopied] = useState(false);
	const circle = root.circles[cid];
	const notebook = circle.notebooks[nid];
	const mid = circle.member_id;
	const [curPgid, setCurpgid] = useState(-1);
	const [tab, setTab] = useState(notebook.report ? 'report' : 'pages');

	const page = notebook.pages[curPgid] || {};

	const copyRef = useRef(null);

	const openLastPg = () => {
		const pgid = last_page_id(notebook);
		if (pgid >= 0)
			setCurpgid(pgid);
	}

	const setIsFullScreen = val => {
		root.settings.isFullScreen = val;
		setIsFullScreen2( val );
	}

	useEffect( () => {
		if (curPgid < 0) { 
			openLastPg();
		}
	});

	console.log('Notebook', notebook.id, {notebook, page}, );

	const toggleFav = async (e) => {
		const aid =  notebook.my_author_id;
		const data = await server_post(
			`/api/notebook/${nid}/${aid}/toggle_favorite/`,
				{nid, aid},
				);

		if (data) {
			circle.notebooks[nid].is_favorite = data.is_favorite;
			refresh();
		}
	}

	const rmNotebook = async (e) => {
		const data = await server_post(
				'/api/notebook/delete/',
				{nid, mid});

		if (data) {
			delete circle.notebooks[nid];
			refresh();
			openFirstNb();
			notify({message: 'Deleted'});
		}
	}

	const emailMe = async (e) => {
		const aid =  notebook.my_author_id;
		const data = await server_post(
				'/api/notebook/emailme/',
				{aid});

		if (data && !data.error) {
			notify({message: 'Email sent. Check junk mail also'});
		}
	}

	const reloadNotebook = async (e) => {
		const pgid = last_page_id(notebook);
		const data = await server_post(
				'/api/notebook/reload/',
				{nid, mid, pgid});

		if (data.pages && Object.keys(data.pages).length ) {
			notebook.pages = {...notebook.pages, ...data.pages};
			refresh();
			notify({message: 'updated'});
		}
	}

	const nextPage = async (e) => {
		notebook.is_new = false;
		setCurpgid(next_page_id( notebook, curPgid ));
		refresh();
	}

	const previousPage = async (e) => {
		notebook.is_new = false;
		setCurpgid(previous_page_id( notebook, curPgid ));
		refresh();
	}

	const handleCopy = () => {
		// setCopied(true);
		// setTimeout(()=>setCopied(false), 2000);
		notify({message: 'Copied'});
	}

	return (
	<>
{notebook.report ? <MyTabs tab={tab} setTab={setTab} /> : null}
<Box p="2" my="4" data-nid={notebook.id} className="notebook notebook-open" >
<Box p="0" m="1" >
{tab == 'report' ? 
	<ShowReport cid={cid} nid={nid} pgid={curPgid} refresh={refresh} 
		nextPage={nextPage} copyRef={copyRef}
		isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen}
		/>
	:
	<ShowPage cid={cid} nid={nid} pgid={curPgid} refresh={refresh} 
		nextPage={nextPage} 
		isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen}
		/>
}
</Box>
</Box>

    {/* Footer */}
    <Flex justify="between" p="2" mt="4" align="center"
    	className="dream-footer"
    >
      <Flex gap="3" align="center" >

        <IconButton variant="ghost" onClick={toggleFav} >
	  {notebook.is_favorite?  <HeartFilledIcon /> : <HeartIcon />}
	</IconButton>
        <AccessMenu notebook={notebook} refresh={refresh} />
      </Flex>

      {tab == 'report' ? 
      <IconButton variant="ghost" >
	<CopyToClipboard
		text={copyRef.current ? copyRef.current.innerText : ''}
		onCopy={handleCopy}
	>
	  <CopyIcon />
	</CopyToClipboard>
	</IconButton>
      :
      <PageNav page={page} notebook={notebook}
      		nextPage={nextPage} previousPage={previousPage} />
	}

      <Flex gap="3">
        <IconButton variant="ghost" onClick={rmNotebook} >
	  <Cross1Icon />
	</IconButton>
        <IconButton variant="ghost" onClick={reloadNotebook} >
	  <ReloadIcon />
	</IconButton>

           <Tooltip content="Full Screen">
        <IconButton variant="ghost" onClick={e=>setIsFullScreen(true)} >
	  <EnterFullScreenIcon />
	</IconButton>
	   </Tooltip>
        <NotebookMenu rmNotebook={rmNotebook} emailMe={emailMe} />
      </Flex>
    </Flex>
    </>
	);
}

function EditName({nid, circle}) {
	const notebook = circle.notebooks[nid];
	const [inEdit, setInEdit] = useState(false);
	const [name, setName] = useState(notebook.name);
	const nameRef = useRef(null);

	const toggle = e => {
		e.stopPropagation();
		setInEdit( prev => !prev );
	}

	const save = async(e) => {
		e.stopPropagation();

		const aid = notebook.my_author_id;
		const data = await server_post(
			`/api/notebook/mod/`,
				{aid, op: 'name', name},
				);

		if (data) {
			circle.notebooks[nid] = {...notebook, ...data[nid]};
			setName( data[nid].name );
			toggle(e);
		}
	}


	useEffect( () => {
		if (inEdit && nameRef.current) 
			nameRef.current.focus();
	}, [inEdit]);


	return (
	<>
	{inEdit ? 
	<Flex align="center" gap="2" >
		<Box maxWidth="6rem">
			<TextField.Root value={notebook.name} 
			  ref={nameRef}
			  value={name}
			  onChange={e=>setName(e.target.value)}
			/>
		</Box>
		<IconButton variant="ghost" onClick={save} >
		  <CheckIcon />
		</IconButton>
		<IconButton variant="ghost" onClick={toggle} >
		  <Cross2Icon />
		</IconButton>
	</Flex>
		:
	<LinkRadix href="#" onClick={toggle} >
		<Text>
			{notebook.name}
		</Text>
	</LinkRadix>
	}
	</>
	);
}


function NotebookHead({cid, nid, refresh, search, curnb, setCurnb, openFirstNb}) {
	const root = window.my;
	const circle = root.circles[cid];
	const notebook = circle.notebooks[nid];
	const iscur = (nid == curnb);

	// console.log('NotebookHead', {notebook});

	return (
	<>
<Box p="2" my="4" data-did={nid} 
	className={"notebook-head " + (iscur ? 'notebook-cur' : '')}
	onClick={e => setCurnb(nid == curnb ? 0 : nid)}
>
<Flex justify="between" align="center" >
	<NotebookIcon />
	<Flex flexGrow="1" px="2" gap="2" align="center" >
		<MiniDateStr date={notebook.dt_added} /> 
		<Separator orientation="vertical" />
		<EditName nid={nid} circle={circle} />
	</Flex>
	<Flex gap="2">
	{notebook.is_new ? <FlagIcon color="green" /> : null}
	<HasBusyPages notebook={notebook} />
	{ iscur ?  <ChevronDownIcon /> : <ChevronRightIcon /> }
	</Flex>
</Flex>
</Box>
{nid == curnb && 
	<ShowNotebook cid={cid} nid={nid} refresh={refresh} 
		openFirstNb={openFirstNb}
	/>
}
	</>
	);
}


// This shows notebooks in a given circle
function ListNotebooks({cid, search, curnb, setCurnb, addNotebook}) {
	const [count, setCount] = useState(0);
	const refresh = () => setCount(prevCount => prevCount + 1);
	console.log('ListNotebooks', count);

	const root = window.my;
	const circle = root.circles[cid];
	
	const narrow = nid => {
		const notebook = circle.notebooks[nid];

		if (search.fav && !notebook.is_favorite)
			return false;

		if (search.text) {
			const regexp = new RegExp(search.text, 'i');
			if (!regexp.test(notebook.name))
				return false;
		}

		return true;
	}
	const nids = Object.keys(circle.notebooks)
			.filter(nid => narrow(nid))
			.map(Number)
			.sort((a,b) => b - a);
	const hasAdded = useRef(false);

	const openFirstNb = () => {
		// Get the latest.. above nids could be stale.
		const anids = Object.keys(circle.notebooks);
		const tnids = anids
				.filter(nid => narrow(nid))
				.map(Number)
				.sort((a,b) => b - a);
		const nid = tnids.length ? tnids[0] : -1;
		if (nid < 0) {
			if (!hasAdded.current) {
				addNotebook();
				hasAdded.current = true;
			}
		}
		else {
			setCurnb(nid);
		}
	}

	useEffect( () => {
		if (curnb < 0) 
			openFirstNb();

		window.my.handlers.refreshListNotebooks = refresh;
	}, []);

	return (
	<>
	{nids.map(nid => <NotebookHead cid={cid} nid={nid}
		key={nid} 
		count={count}
		refresh={refresh}
		search={search}
		curnb={curnb}
		setCurnb={setCurnb}
		openFirstNb={openFirstNb}
		/>)}
	</>
	);
}

export {ListNotebooks, ShowNotebook};
